/* You can add global styles to this file, and also import other style files */
body {
  background-color: #f8f9fa !important;
}

.widget-box {
  height: 550px;
  margin: 20px;
  width: 720px;
  border-radius: 10px !important;
  box-shadow: 3px 3px 5px 6px #ccc;
  display: inline-block;
}

.background-image {
  background-image: url('./assets/images/Acs-001.jpg');
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.myClass {
  padding-top: 30px;
  color: #fff;
  font: 14px/18px "Source Sans Pro", sans-serif;
  letter-spacing: .25px;
}

.buttons-bar a {
  margin-left: 5px;
}

.button_default_lemon,
.button_default_lemon:active,
.button_default_lemon:focus,
.button_default_lemon:hover,
.button_default_lemon:visited {
  text-decoration: none;
  color: #000;
  background: #ffdd2c;
  border-color: #ffdd2c;
}

.button_default_white,
.button_default_white:active,
.button_default_white:focus,
.button_default_white:hover,
.button_default_white:visited {
  text-decoration: none;
  color: #000;
  background: #fff;
  border-color: #fff;
}

.button_default_lemon,
.button_default_lemon:active,
.button_default_lemon:focus,
.button_default_lemon:hover,
.button_default_lemon:visited {
  text-decoration: none;
  height: 40px;
  width: auto;
  min-width: 200px;
  font: 600 17px/24px "Source Sans Pro", sans-serif;
  letter-spacing: .5px;
  box-sizing: border-box;
  padding: 6px 30px;
  border: solid 2px transparent;
  border-radius: 2px;
  text-align: center;
  display: inline-block;
  white-space: nowrap;
}

.button_default_white,
.button_default_white:active,
.button_default_white:focus,
.button_default_white:hover,
.button_default_white:visited {
  text-decoration: none;
  height: 40px;
  width: auto;
  min-width: 200px;
  font: 600 17px/24px "Source Sans Pro", sans-serif;
  letter-spacing: .5px;
  box-sizing: border-box;
  padding: 6px 30px;
  border: solid 2px transparent;
  border-radius: 2px;
  text-align: center;
  display: inline-block;
  white-space: nowrap;
}
